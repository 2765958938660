import { LaunchSource } from '~/analytics/AuthEvent'
import { Typography } from '~/components/shared/text/Typography'

export const useRegisterHomeTitle = (
  isInlinePurchase: boolean,
  launchSource: LaunchSource,
  isSupportedContextualAction: boolean,
  profileName?: string
) => {
  if (isInlinePurchase) {
    if (launchSource === 'buy_service') {
      return (
        <Typography
          variant="h3"
          mobileVariant="h2"
          mb={32}
          align="center"
        >
          Create a{' '}
          <Typography
            span
            variant="h3Italic"
            mobileVariant="h2Italic"
          >
            free account
          </Typography>
          <br />
          to finish checkout
        </Typography>
      )
    } else {
      return (
        <Typography
          variant="h3"
          mobileVariant="h2"
          mb={32}
          align="center"
        >
          Join Thatch
          <br />
          <Typography
            span
            variant="h3Italic"
            mobileVariant="h2Italic"
          >
            to purchase.
          </Typography>
        </Typography>
      )
    }
  }

  if (launchSource == 'thatch_gpt') {
    return (
      <Typography
        variant="h3"
        mobileVariant="h2"
        mb={32}
        align="center"
      >
        Join Thatch{" "}
        <Typography
          span
          variant="h3Italic"
          mobileVariant="h2Italic"
        >
          for free
        </Typography>
        <br />
        to continue the chat.
        
      </Typography>
    )
  }

  if (launchSource == 'personalize') {
    return (
      <Typography
        variant="h3"
        mobileVariant="h2"
        mb={32}
        align="center"
      >
        Join Thatch{" "}
        <Typography
          span
          variant="h3Italic"
          mobileVariant="h2Italic"
        >
          for free
        </Typography>
        <br />
        to start planning.
        
      </Typography>
    )
  }

  if (isSupportedContextualAction) {
    if (launchSource == 'save_guide') {
      return (
        <Typography
          variant="h3"
          mobileVariant="h2"
          mb={32}
          align="center"
        >
          Join Thatch{' '}
          <Typography
            span
            variant="h3Italic"
            mobileVariant="h2Italic"
          >
            for free
          </Typography>
          <br />
          to save this guide.
          <br />
        </Typography>
      )
    }

    if (launchSource == 'message_me') {
      return (
        <Typography
          variant="h3"
          mobileVariant="h2"
          mb={32}
          align="center"
        >
          To send a messge,
          <br />
          join Thatch{" "}
          <Typography
            span
            variant="h3Italic"
            mobileVariant="h2Italic"
          >
            for free
          </Typography>
        </Typography>
      )
    }

    if (launchSource == 'add_place') {
      return (
        <Typography
          variant="h3"
          mobileVariant="h2"
          mb={32}
          align="center"
        >
          Join Thatch{' '}
          <Typography
            span
            variant="h3Italic"
            mobileVariant="h2Italic"
          >
            for free
          </Typography>
          <br />
          to save this place.
        </Typography>
      )
    }

    if (launchSource == 'subscribe' && profileName) {
      return (
        <Typography
          variant="h3"
          mobileVariant="h2"
          mb={32}
          align="center"
        >
          Never miss updates from
          <br />
          <Typography
            span
            variant="h3Italic"
            mobileVariant="h2Italic"
          >
            {profileName}
          </Typography>
        </Typography>
      )
    }
  }

  return (
    <Typography
      variant="h3Italic"
      mobileVariant="h2Italic"
      mb={{ base: 96, sm: 88 }}
      align="center"
    >
      Join{' '}
      <Typography
        span
        variant="h3"
        mobileVariant="h2"
      >
        Thatch.
      </Typography>
    </Typography>
  )
}
